import { render, staticRenderFns } from "./LottosetRecentResults.vue?vue&type=template&id=4c412766&scoped=true&"
import script from "./LottosetRecentResults.vue?vue&type=script&lang=js&"
export * from "./LottosetRecentResults.vue?vue&type=script&lang=js&"
import style0 from "./LottosetRecentResults.vue?vue&type=style&index=0&id=4c412766&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c412766",
  null
  
)

export default component.exports